import React from 'react';

const ShimmerEffect = () => {
    return (
        <div className="shimmer-wrapper xl:mx-2 relative w-40 h-40 ">
            <div className="shimmer"></div>
        </div>
    );
};

export default ShimmerEffect;
