import React, { useEffect, useRef, useState } from "react";
import Reference from "./Reference";
import Carousel from "react-elastic-carousel";
import ShimmerEffect from "./ShimmerEffect";
import axios from "axios";
import { serverAPI } from "../utils/envConfig";

const References = ({ developers }) => {
    const carouselRef = useRef();
    const breakPoints = [
        { width: 1, itemsToShow: 2 },
        { width: 550, itemsToShow: 3 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 6 },
    ];

    const renderContent = () => {
        if (!developers || developers.length === 0) {
            // Render shimmer effect as placeholders when no data
            return Array(6).fill(0).map((_, i) => 
                <>
                <div className="flex md:justify-start justify-center lg:my-10 container mx-auto max-w-[1570px] xl:max-w-[1199px] 3xl:max-w-[1500px]">
                <ShimmerEffect key={i} />
                </div>
                </>);
        } else {
            // Render actual developers data
            return developers.map((developer, i) => (
                <Reference key={i} imagePath={developer.logo} />
            ));
        }
    };

    return (
        <div className="relative bg-white mx-auto container 3xl:max-w-[1500px]">
            <div className="flex justify-start mb-5">
                <h6 className="text-navy font-Lexend mx-10 mt-6">
                    <span className="font-bold 3xl:pl-0 4xs:text-2xl 3xl:text-3xl">
                        Official Partner
                    </span>
                    <span className="4xs:text-2xl 3xl:text-3xl">{" "}of</span>
                </h6>
            </div>
            <div className="flex md:justify-start justify-center lg:my-10 container mx-auto max-w-[1570px] xl:max-w-[1199px] 3xl:max-w-[1500px]">
                <Carousel
                    breakPoints={breakPoints}
                    enableAutoPlay={true}
                    showArrows={false}
                    pagination={false}
                    autoPlaySpeed={1500}
                    transitionMs={2000}
                    ref={carouselRef}
                >
                    {renderContent()}
                </Carousel>
            </div>
        </div>
    );
};

export default References;
